<template>
<v-col cols="12" md="4" lg="4" offset-md="4" offset-lg="4">
  <v-card class="elevation-1" :loading="loading">
    <v-card-title class="py-9">
      Report
    </v-card-title>
    <v-card-text>
      <v-form>
  
        <v-row class="mb-0 pb-0">
          <v-col cols="12" class="pb-0 mb-0">
            <v-autocomplete
              v-model="report"
              outlined
              dense
              clearable
              label="Select Report"
              item-text="title"
              item-value="id"
              :items="['Cashier Responsibility','Daily Sales Report']"
              :hide-details="formErrorFields.reportErrorMessage.length === 0"
              :error-messages="formErrorFields.reportErrorMessage"
              @change="formError().remove('report', formErrorFields)"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="m-0 p-0 mb-2">
          <v-col
            cols="12"
            class="m-0 p-0"
          >
            <!-- Sales Date -->
            <v-dialog
              ref="dialog"
              v-model="modalSalesDate"
              :return-value.sync="salesDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="salesDate"
                  label="Sales Date"
                  outlined
                  dense
                  placeholder="Sales Date"
                  v-bind="attrs"
                  :append-icon="icon.calendar"
                  :hide-details="formErrorFields.salesDateErrorMessage.length === 0"
                  :error-messages="formErrorFields.salesDateErrorMessage"
                  @change="formError().remove('salesDate', formErrorFields)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="salesDate"
                scrollable
              >
                <v-btn
                  text
                  color="primary"
                  @click="modalSalesDate=false"
                >
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modalSalesDate=false"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      
        <v-btn
          color="success"
          :loading="loading"
          @click="submit">
          Generate
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
  <snackbar
    v-if="message.length > 0"
    :show="message.length > 0"
    :text="message"
    :color="messageStatus === 200 ? 'success' : 'error'"
    @close="message=''"
  ></snackbar>
</v-col>
</template>

<script>
import axios from 'axios'
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarOutline } from '@mdi/js'
import blob from '@/composables/blob'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import fnx from '@/functions/fn'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const balance = ref(0)
    const loading = ref(false)
    const message = ref('')
    const messageStatus = ref('')
    const report = ref('')
    const formErrorFields = ref({
      reportErrorMessage: '',
      salesDateErrorMessage: '',
    })

    const d = new Date()
    const modalSalesDate = ref(false)
    const salesDateValue = d.toISOString().substring(0, 10)
    const salesDate = ref('')

    const error = ref('')
    const reportData = ref(null)

    const icon = {
      calendar: mdiCalendarOutline,
    }

    const reset = () => {
      report.value = ''
      salesDate.value = ''
    }

    const submit = async () => {
      const formData = {
        report: report.value,
        salesDate: salesDate.value,
      }

      await blob(`${process.env.VUE_APP_URI}/api/stockist/report/${fnx.base64_encode(formData)}`, reportData, loading, error)
      if (error.value.data !== undefined) {
        console.log(error)
        message.value = error.value.statusText
        messageStatus.value = error.value.status
      } else {
        const url = window.URL.createObjectURL(new Blob([reportData.value], { type: 'application/pdf' }))
        window.open(url, '_blank')
      }
    }

    return {
      icon,
      report,
      reset,
      submit,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,

      modalSalesDate,
      salesDateValue,
      salesDate,
    }
  },

  components: {
    snackbar,
  },
}
</script>
